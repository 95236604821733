import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import type {Feature, Geometry} from "geojson";

declare module '#app' {
    interface NuxtApp {
        $createMap(containerId: string, lng: number, lat: number, zoomLevel: number, title: string): void,

        $createMapWithRadiusCircle(containerId: string, lng: number, lat: number, zoomLevel: number, radius: number): void,

        $createMapWithMarkers(containerId: string, zoomLevel: number, data: DataObject[], defaultLng: number, defaultLat: number, dataType: string): void,

        $updateMapSourceData(data: DataObject[]): void,

        $flyToCoordinates(lng: number, lat: number): void,
    }
}

interface DataObject {
    id: string | number,
    lng: number,
    lat: number,
    title: string | null,
    name: string | null,
    marker: string | null,
    price: string | null,
    image: string | null,
    logo: string | null,
    logo_thumb: string | null,
    rating: string | null,
    slug: string,
}

interface ExtractedObject {
    coordinates: [number, number],
    title: string | null,
    price: string | null,
    image: string | null,
    name: string | null,
    logo: string | null,
    rating: string | null,
}

interface MapEventData {
    features: FeaturedData[],
    lngLat: { lng: number, lat: number }
}

interface FeaturedData {
    geometry: {
        coordinates: []
    },
    properties: DataObject
}

export default defineNuxtPlugin(nuxtApp => {
    mapboxgl.accessToken =
        useRuntimeConfig().public.mapboxAccessToken;
    let map = ref<null | any>(null);
    const customMarkerUrl = "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png";
    const resourceMapPopUp = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    function setUpMap(containerId: string, lat: number, lng: number, zoomLevel: number) {
        return new mapboxgl.Map({
            container: containerId,
            center: [lng, lat],
            zoom: zoomLevel,
            style: "mapbox://styles/mapbox/streets-v11"
        }) as mapboxgl.Map;
    }

    nuxtApp.provide('createMap', (containerId: string, lng: number, lat: number, zoomLevel: number, title: string) => {
        map.value = setUpMap(containerId, lat, lng, zoomLevel);
        map.value?.on("load", () => {
            map.value?.loadImage(
                customMarkerUrl,
                (error: any, image: HTMLImageElement) => {
                    if (error) throw error;
                    addCustomMarkerImageToMap(map.value, image);
                    addPointsSourceToMapWithSinglePointAndTitle(map.value, lng, lat, title);
                    addMarkerLayerToMap(map.value, "pointsLayer", "title", "custom-marker");
                }
            );
        });
    })

    nuxtApp.provide('createMapWithRadiusCircle', (containerId: string, lng: number, lat: number, zoomLevel: number, radius: number) => {
        map.value = setUpMap(containerId, lat, lng, zoomLevel);
        map.value?.on("load", () => {
            addPointsSourceToMapWithSinglePointAndTitle(map.value, lng, lat, '');
            addCircleLayerToMap(map.value, radius);
            map.value?.resize();
        });
    })

    nuxtApp.provide('createMapWithMarkers', (containerId: string, zoomLevel: number, data: DataObject[], defaultLng: number, defaultLat: number, dataType: string) => {
        let firstDataPiece = data[0];
        let longitude = firstDataPiece ? firstDataPiece.lng : defaultLng;
        let latitude = firstDataPiece ? firstDataPiece.lat : defaultLat;
        map.value = setUpMap(containerId, latitude, longitude, zoomLevel);
        map.value?.on("load", () => {
            initializeMarkers(map.value);
            addPointsSourceToMapWithMultiplePointsAndPropertiesFromData(map.value, data);
            addMarkerLayerToMap(map.value, "pointsLayer", dataType == 'businesses' || dataType == 'islamic-centers' ? "name" : "title", null);
        });

        //Marker Hover Pop up
        map.value?.on('mouseenter', 'pointsLayer', (e: MapEventData) => {
            // Change the cursor style as a UI indicator.
            map.value.getCanvas().style.cursor = 'pointer';
            let extractedObject = extractProperDataByType(e, dataType);

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            if (extractedObject) {
                while (Math.abs(e.lngLat.lng - extractedObject.coordinates[0]) > 180) {
                    //       coordinates[0] += e.lngLat.lng > extractedObject.coordinates[0] ? 360 : -360;
                    extractedObject.coordinates[0] += e.lngLat.lng > extractedObject.coordinates[0] ? 360 : -360;
                }
            }

            if (extractedObject) {
                resourceMapPopUp.setLngLat(extractedObject.coordinates).setHTML(
                    resolveResourceHTMLByType(dataType, extractedObject)
                ).addTo(map.value);
            }
        });

        map.value.on('mouseleave', 'pointsLayer', () => {
            map.value.getCanvas().style.cursor = '';
            resourceMapPopUp.remove();
        });

        //On Click Goto Certain Route
        map.value.on('click', 'pointsLayer', (e: MapEventData) => {
            const slug = e.features[0].properties.slug;
            navigateTo({
                name: dataType == 'classified' ? 'classified-slug' : dataType == 'news' ? 'news-announcements-slug' : dataType == 'islamic-centers'? 'islamic-centers-slug' : 'businesses-slug',
                params: {
                    slug: slug
                }
            })
        })
    })

    nuxtApp.provide('updateMapSourceData', (data: DataObject[]) => {
        if (map.value != undefined && map.value != null && map.value.getSource('points') != undefined) {
            map.value.getSource('points').setData({
                type: "FeatureCollection",
                features: getPointsFromObjects(data)
            });
        }
    })

    nuxtApp.provide('flyToCoordinates', (lng: number, lat: number) => {
        if (map.value != undefined && map.value != null) {
            map.value.flyTo({
                center: [
                    lng,
                    lat],
                zoom: 11,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }
    })

    function addCustomMarkerImageToMap(map: mapboxgl.Map, image: HTMLImageElement) {
        map.addImage("custom-marker", image);
    }

    function addPointsSourceToMapWithSinglePointAndTitle(map: mapboxgl.Map, lng: number, lat: number, title: string) {
        map.addSource(
            "points",
            {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [lng, lat]
                            },
                            properties: {
                                title: title != null ? title : ""
                            }
                        }
                    ]
                }
            });
    }

    function addMarkerLayerToMap(map: mapboxgl.Map, layerId: string, textPropertyName: string, markerName: string|null) {
        map.addLayer({
            id: layerId,
            type: "symbol",
            source: "points",
            layout: {
                "icon-image": markerName != null ? markerName : ["get", "marker"],
                "text-field": ["get", textPropertyName],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-offset": [0, 1.25],
                "text-anchor": "top"
            }
        });
    }

    function addCircleLayerToMap(map: mapboxgl.Map, radius: number) {
        map.addLayer({
            id: 'circleLayer',
            type: 'circle',
            source: 'points',
            paint: {
                'circle-radius': radius,
                'circle-color': '#124DBD',
                'circle-opacity': 0.5
            },
        });
    }

    function initializeMarkers(map: mapboxgl.Map) {
        map.loadImage(
            "/images/main/marker-red.png",
            (error: Error | null | undefined, image: HTMLImageElement | ImageBitmap | ImageData | null | undefined) => {
                if (error) throw error;
                if (image) {
                    map.addImage('red-marker', image);
                } else {
                    console.log('No Red Marker Image', image);
                }
            })

        map.loadImage(
            "/images/main/marker-orange.png",
            (error: Error | null | undefined, image: HTMLImageElement | ImageBitmap | ImageData | null | undefined) => {
                if (error) throw error;
                if (image) {
                    map.addImage('orange-marker', image);
                } else {
                    console.log('No Orange Marker Image', image);
                }
            });
    }

    function addPointsSourceToMapWithMultiplePointsAndPropertiesFromData(map: mapboxgl.Map, data: DataObject[]) {
        map.addSource(
            "points",
            {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: getPointsFromObjects(data)
                }
            });
    }

    function getPointsFromObjects(objects: DataObject[]) {
        return objects.map(obj => {
            return {
                id: obj.id,
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [obj.lng, obj.lat]
                },
                properties: {
                    title: obj.title != null ? obj.title : null,
                    name: obj.name != null ? obj.name : null,
                    marker: obj.marker != null ? obj.marker : "red-marker",
                    price: obj.price != null ? obj.price : null,
                    image: obj.image != null ? obj.image : null,
                    logo: obj.logo_thumb != null ? obj.logo_thumb : obj.logo != null? obj.logo : null,
                    rating: obj.rating != null ? obj.rating : null,
                    slug: obj.slug
                }
            } as Feature
        })
    }

    function extractProperDataByType(e: MapEventData, dataType: string):
        ExtractedObject | null {
        console.log(e.features[0].properties, dataType)
        if (dataType == 'classified') {
            return {
                'coordinates': [e.features[0].geometry.coordinates.slice()[0] as number, e.features[0].geometry.coordinates.slice()[1] as number] as [number, number],
                'title': e.features[0].properties.title as string,
                'price': e.features[0].properties.price as string,
                'image': e.features[0].properties.image as string,
                'name': null,
                'logo': null,
                'rating': null,
            }
        }

        if (dataType == 'islamic-centers') {
            return {
                'coordinates': [e.features[0].geometry.coordinates.slice()[0] as number, e.features[0].geometry.coordinates.slice()[1] as number] as [number, number],
                'name': e.features[0].properties.name as string,
                'image': null,
                'logo': e.features[0].properties.logo as string,
                'rating': null,
                'title': null,
                'price': null,
            }
        }

        if (dataType == 'news') {
            return {
                'coordinates': [e.features[0].geometry.coordinates.slice()[0] as number, e.features[0].geometry.coordinates.slice()[1] as number] as [number, number],
                'title': e.features[0].properties.title as string,
                'image': e.features[0].properties.image as string,
                'price': null,
                'name': null,
                'logo': null,
                'rating': null,
            }
        }

        if (dataType == 'businesses') {
            return {
                'coordinates': [e.features[0].geometry.coordinates.slice()[0] as number, e.features[0].geometry.coordinates.slice()[1] as number] as [number, number],
                'name': e.features[0].properties.name as string,
                'logo': e.features[0].properties.logo as string,
                'rating': e.features[0].properties.rating as string,
                'price': null,
                'title': null,
                'image': null
            }
        }

        return null;
    }

    function resolveResourceHTMLByType(type: string, extractedObject: ExtractedObject) {
        if (type == 'classified') {
            return `<div class="flex flex-col justify-center items-center p-2">
        <div class="flex flex-row justify-center items-center">
          <img src="${extractedObject.image}" class="mx-2" width="70" height="70" />
          <p class="font-semibold">${extractedObject.title}</p>
        </div>
        <h3 class="text-lg font-bold" v-if="price!=null">${'$' + extractedObject.price}</h3> 
        </div>`
        }
        if (type == 'news') {
            return `<div class="flex flex-col justify-center items-center p-2">
      <div class="flex flex-row justify-center items-center">
        <img src="${extractedObject.image}" class="mx-2" width="70" height="70" />
        <p class="font-semibold">${extractedObject.title}</p>
      </div>
      </div>`
        }
        if (type == 'businesses') {
            return `<div class="flex flex-col justify-center items-center p-2">
        <div class="flex flex-row justify-center items-center">
          <img src="${extractedObject.logo}" class="mx-2" width="70" height="70" />
          <div class="flex flex-col items-start justify-center">
            <p class="font-semibold">${extractedObject.name}</p>
            <p><span class="mdi mdi-star text-a-primary text-lg"></span>${extractedObject.rating}</p>
          </div>
        </div>
        </div>`
        }
        if (type == 'islamic-centers') {
            return `<div class="flex flex-col justify-center items-center p-2">
        <div class="flex flex-row justify-center items-center">
          <img src="${extractedObject.logo}" class="mx-2" width="70" height="70" />
          <div class="flex flex-col items-start justify-center">
            <p class="font-semibold">${extractedObject.name}</p>
          </div>
        </div>
        </div>`
        }
        return '';
    }


});