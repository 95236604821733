<template>
  <div class="h-screen text-a-neutral font-sans tracking-wide overflow-hidden">
    <DashboardNav class="hidden md:flex" />
    <DashboardNavMobile class="flex md:hidden" />
    <div class="flex flex-row w-full h-full">
      <div class="w-2/12 hidden md:flex flex-none md:w-2/12 pt-8 border-r-2">
        <BaseDashboardSideMenu
          :menuItems="menuItems"
          :dashboardItem="business"
          dashboardPathName="dashboard-businesses"
          :key="useRoute().fullPath"
        >
          <template v-slot:actionButton>
            <BaseButton
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
              primary-color-class="a-neutral-dimmer"
              text-color-class="a-neutral-dark"
              @click="showDeactivationModal()"
              v-if="business && business.status != 'Deactivated'"
            >
            <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:archive-remove-outline"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold">Deactivate Business</span>
              </span> 
            </BaseButton>
          </template>
        </BaseDashboardSideMenu>
      </div>
      <div class="flex md:hidden">
        <BaseDashboardSideMenuMobile
        :menuItems="menuItems"
          :dashboardItem="business"
          dashboardPathName="dashboard-businesses"
          :key="useRoute().fullPath"
        >
          <template v-slot:actionButton>
            <BaseButton
              text-size-class="text-sm xl:text-base"
              primary-color-class="a-neutral-dimmer"
              text-color-class="a-neutral-dark"
              @click="showDeactivationModal()"
              v-if="business && business.status != 'Deactivated'"
            >
            <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:archive-remove-outline"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold">Deactivate Business</span>
              </span> 
            </BaseButton>
          </template>

        </BaseDashboardSideMenuMobile>
      </div>
      <div class="flex flex-col w-full md:w-10/12 pt-8 pb-48 overflow-y-auto bg-a-background-light-primary">
        <slot />
      </div>
      <BaseModal ref="businessDeactivationModalRef">
              <DashboardBusinessDeactivationModalTemplate
                :business="business"
                @closeModal="hideDeactivationModal()"
              />
            </BaseModal>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseModal } from '~/types';
import type {DashboardMenuItem} from "~/types/dashboard";

const route = useRoute();

const menuItems = ref<DashboardMenuItem[]>([
  {
          title: "All Details",
          link: {
            routeName: "dashboard-businesses-slug",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:menu",
          badge: {
        type: "normal",
        value: 0,
          },
        },
        {
          title: "Edit",
          link: {
            routeName: "dashboard-businesses-slug-edit",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:clipboard-edit-outline",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Reviews & Rating",
          link: {
            routeName: "dashboard-businesses-slug-reviews",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:star-outline",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Coupons",
          link: {
            routeName: "dashboard-businesses-slug-coupons",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:ticket-percent-outline",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Sales",
          link: {
            routeName: "dashboard-businesses-slug-sales",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:brightness-percent",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Posts",
          link: {
            routeName: "dashboard-businesses-slug-posts",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:post-outline",
        },
        {
          title: "General Products",
          link: {
            routeName: "dashboard-businesses-slug-general-products",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:package-variant-closed",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Custom Products",
          link: {
            routeName: "dashboard-businesses-slug-custom-products",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:package-variant",
          badge: {
            type: "error",
            value:  0,
      },
          },
        {
          title: "General Services",
          link: {
            routeName: "dashboard-businesses-slug-general-services",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:toolbox-outline",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Custom Services",
          link: {
            routeName: "dashboard-businesses-slug-custom-services",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:progress-wrench",
          badge: {
            type: "error",
            value:  0,
      },
          },
        {
          title: "Subscriptions & Billing",
          link: {
            routeName: "dashboard-businesses-slug-billing",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:credit-card-outline",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Timing",
          link: {
            routeName: "dashboard-businesses-slug-timing",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:clock-time-four-outline",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Amenities",
          link: {
            routeName: "dashboard-businesses-slug-amenities",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:account-cog",
          badge: {
        type: "normal",
        value: 0,
      },
        },
        {
          title: "Images Gallery",
          link: {
            routeName: "dashboard-businesses-slug-gallery",
            routeParams: { slug: route.params.slug },
          },
          icon: "mdi:image-album",
          badge: {
        type: "normal",
        value: 0,
      },
        },
]);

const business = await useBusinessesDataManager().getCurrentDashboardBusiness();

const unCategorizedBusinessCustomProducts = computed(()=>{
      let unCategorizedCustomProducts =
        business.value?.un_categorized_custom_products;
        const menuItem = menuItems.value[7] as DashboardMenuItem;
        if (menuItem && menuItem.badge) {
          menuItem.badge.value = unCategorizedCustomProducts as number;
        }
      return unCategorizedCustomProducts;
});
const unCategorizedBusinessCustomServices = computed(()=>{
      let unCategorizedCustomService =
        business.value?.un_categorized_custom_services;
        const menuItem = menuItems.value[7] as DashboardMenuItem;
        if (menuItem && menuItem.badge) {
      menuItem.badge.value = unCategorizedCustomService as number;
    }
      return unCategorizedCustomService;
    });
const businessSlug = computed(()=>{
      return route.params.slug;
    });


watch(() => route.params.slug, () => {
    menuItems.value.forEach(
      (menuItem) => (menuItem.link.routeParams.slug = business.value?.slug as string)
    );
  });

watch(()=>unCategorizedBusinessCustomServices,()=>{
  const menuItem = menuItems.value[9] as DashboardMenuItem;
  if (menuItem && menuItem.badge) {
      menuItem.badge.value = business.value?.un_categorized_custom_services as number;
    }
});

watch(()=>unCategorizedBusinessCustomProducts,()=>{
  const menuItem = menuItems.value[7] as DashboardMenuItem;
  if (menuItem && menuItem.badge) {
      menuItem.badge.value = business.value?.un_categorized_custom_products as number;
    }
});


const businessDeactivationModalRef = ref<BaseModal | null>(null);

function showDeactivationModal() {
  if (businessDeactivationModalRef.value) {
    businessDeactivationModalRef.value.show();
  }
};
function hideDeactivationModal() {
  if (businessDeactivationModalRef.value) {
    businessDeactivationModalRef.value.hide();
  }
};

</script>

<style></style>
