import type {
    ApiError, ClassifiedAdSearchData,
    FundraisingCampaignDonationList,
    FundraisingCampaignReportType, FundraisingCampaignSearchData,
    FundraisingInstantSearchData, MainFundraisingCampaignsData, MainSingleFundraisingCampaignData,
    SavedFundraisingCampaign
} from "~/types";

export const useMainFundraisingDataService = () => {
    const nuxtApp = useNuxtApp();
    const baseUrl = useRuntimeConfig().public.baseApiUrl;
    const route = useRoute();

    const fetchMainFundraisingCampaign = async () => {
        const {data, error} = await useAsyncData(`fundraising-campaign-${route.params.slug}`,
            () => nuxtApp.$getFundraisingCampaignBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainSingleFundraisingCampaignData;
        }
    }

    const fetchMainFundraisingCampaignsData = async () => {
        const { data, error, refresh } = await useAsyncData(`main-fundraising-campaigns`,
            () => useNuxtApp().$getFundraisingCampaignsDataFromUrl(`${baseUrl}/fundraising-campaigns`)
        );

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(()=>{
                return {
                    fundraising_campaign_page:{
                        fundraising_campaigns: data.value.data,
                        page_number: data.value.meta.current_page,
                    },
                    pagination_meta_data: data.value.meta,
                    pagination_links: data.value.links,
                } as MainFundraisingCampaignsData
            });

        }
    };

    const fetchMainFundraisingCampaignsDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getFundraisingCampaignsDataFromUrl(url);
            return  {
                fundraising_campaign_page:{
                    fundraising_campaigns: response.data,
                    page_number: response.meta.current_page,
                },
                pagination_meta_data: response.meta,
                pagination_links: response.links,
            } as MainFundraisingCampaignsData;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const fetchFundraisingCampaignReportTypes = async () => {
        const {data, error} = await useAsyncData('fundraising-campaign-report-types',
            () => nuxtApp.$getFundraisingCampaignReportTypes()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as FundraisingCampaignReportType[];
        }
    }

    const requestToCreateNewFundraisingCampaignReport = async (data: object) => {
        try {
            const response = await nuxtApp.$createFundraisingCampaignReport(data);
            return response.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToSaveAFundraisingCampaign = async () => {
        try {
            const response = await nuxtApp.$saveFundraisingCampaignBySlug(route.params.slug as string);
            return response.data as SavedFundraisingCampaign;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDonateToAFundraisingCampaign = async (donationForm: object) => {
        try {
            const response = await nuxtApp.$donateToFundraisingCampaignBySlug(route.params.slug as string,donationForm);
            return response.data as FundraisingCampaignDonationList;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchInstantSearchResults = async (query: string) => {
        try {
            const response = await nuxtApp.$getInstantSearchResultsForFundraisingByQuery(query)
            return response as FundraisingInstantSearchData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchFundraisingCampaignsBySearchCriteria = async(query:string)=>{
        try{
            const response = await nuxtApp.$getFundraisingCampaignsBySearchCriteria(query);
            return response as FundraisingCampaignSearchData;
        }
        catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchMainFundraisingCampaign,
        fetchMainFundraisingCampaignsData,
        fetchMainFundraisingCampaignsDataFromUrl,
        fetchFundraisingCampaignReportTypes,
        requestToCreateNewFundraisingCampaignReport,
        requestToSaveAFundraisingCampaign,
        requestToDonateToAFundraisingCampaign,

        fetchInstantSearchResults,
        fetchFundraisingCampaignsBySearchCriteria,
    }
}