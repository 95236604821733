import { defineAsyncComponent } from 'vue'
export default {
  "dashboard-business": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-business.vue")),
  "dashboard-classified-ad": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-classified-ad.vue")),
  "dashboard-fundraising-campaign": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-fundraising-campaign.vue")),
  "dashboard-islamic-center": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-islamic-center.vue")),
  "dashboard-main": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-main.vue")),
  "dashboard-news-announcement": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-news-announcement.vue")),
  "dashboard-simple": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/dashboard-simple.vue")),
  default: defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/default.vue")),
  "email-verification": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/email-verification.vue")),
  "login-register": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/login-register.vue")),
  "main-businesses-home": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-businesses-home.vue")),
  "main-businesses-search": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-businesses-search.vue")),
  "main-businesses": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-businesses.vue")),
  "main-classified-home": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-classified-home.vue")),
  "main-classified-search": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-classified-search.vue")),
  "main-classified": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-classified.vue")),
  "main-fundraising-home": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-fundraising-home.vue")),
  "main-fundraising-search": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-fundraising-search.vue")),
  "main-fundraising": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-fundraising.vue")),
  "main-islamic-centers-home": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-islamic-centers-home.vue")),
  "main-islamic-centers-search": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-islamic-centers-search.vue")),
  "main-islamic-centers": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-islamic-centers.vue")),
  "main-news-search": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-news-search.vue")),
  "main-news": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-news.vue")),
  "main-simple": defineAsyncComponent(() => import("/var/www/alahddin-fend/layouts/main-simple.vue"))
}