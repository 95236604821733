<template>
  <div
      class="flex flex-col text-a-neutral bg-a-main-gray relative font-sans tracking-wide"
  >
    <IslamicCentersNav class="hidden md:flex"/>
    <IslamicCentersNavMobile class="flex md:hidden"/>
    <MainSideMenuMobile class="flex md:hidden"/>
    <div class="flex flex-col">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style></style>
