<template>
  <div
      class="flex relative mr-4"
      v-click-outside="() => {
             showExploreDropdown = false;
         }
             "
  >
    <div
        class="flex flex-row items-center text-sm w-auto px-2 h-8 justify-center cursor-pointer select-none bg-a-primary text-a-neutral-darkest rounded shadow-lg"
        @click.native="
                 showExploreDropdown =
                 !showExploreDropdown
                 "
    >
      <p class="font-semibold">
        Explore
      </p>
      <Icon
          :name="showExploreDropdown
                ? 'tabler:chevron-up'
                : 'tabler:chevron-down'"
          class="text-base"
      />
    </div>
    <transition name="dropdown">
      <div
          v-show="showExploreDropdown
            "
          class="flex flex-col absolute -bottom-36 right-40 w-52 text-sm border p-2 left-0 px-1 bg-white text-a-neutral rounded shadow-lg transition-opacity duration-500 ease-out z-50"
      >
        <div
            v-if="!isCurrentRoute('businesses')"
            class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none transition duration-400 ease-in-out"
            @click="gotoExplore('businesses')"
        >
          Businesses
        </div>
        <div
            v-if="!isCurrentRoute('classified')"
            class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none transition duration-400 ease-in-out"
            @click="gotoExplore('classified')"
        >
          Classified
        </div>
        <div
            v-if="!isCurrentRoute('fundraising')"
            class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none transition duration-400 ease-in-out"
            @click="gotoExplore('fundraising')"
        >
          Fundraising
        </div>
        <div
            v-if="!isCurrentRoute('news')"
            class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none transition duration-400 ease-in-out"
            @click="gotoExplore('news')"
        >
          News
        </div>
        <div
            v-if="!isCurrentRoute('islamic-centers')"
            class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none transition duration-400 ease-in-out"
            @click="gotoExplore('islamic-centers')"
        >
          Islamic Centers
        </div>
      </div>
    </transition>
  </div>
</template>
<script
    setup
    lang="ts"
>
const showExploreDropdown =
    ref(false);

function gotoExplore(routeName) {
  showExploreDropdown.value = false
  navigateTo({
    name: routeName
  })
}

function isCurrentRoute(routeName) {
  return useRoute().name === routeName
}
</script>


<style scoped>
.dropdown-enter-active, .dropdown-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.dropdown-enter-from, .dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
} </style>