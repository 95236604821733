import { default as _91id_93JJNH8BNQcsMeta } from "/var/www/alahddin-fend/pages/businesses/[slug]/avail-coupon/[id].vue?macro=true";
import { default as indexAqIOeRSTf6Meta } from "/var/www/alahddin-fend/pages/businesses/[slug]/index.vue?macro=true";
import { default as indexLAk2OzypsDMeta } from "/var/www/alahddin-fend/pages/businesses/index.vue?macro=true";
import { default as searchTzI7gE75r4Meta } from "/var/www/alahddin-fend/pages/businesses/search.vue?macro=true";
import { default as indexnRmazA3OkgMeta } from "/var/www/alahddin-fend/pages/classified/[slug]/index.vue?macro=true";
import { default as indexkGedY9Z07uMeta } from "/var/www/alahddin-fend/pages/classified/index.vue?macro=true";
import { default as search3KUeGOXDFHMeta } from "/var/www/alahddin-fend/pages/classified/search.vue?macro=true";
import { default as amenities5NpY1nOEapMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/amenities.vue?macro=true";
import { default as checkouthzT0aycgmwMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/checkout.vue?macro=true";
import { default as downgradePuN5FhAXaEMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/downgrade.vue?macro=true";
import { default as indexOL9rEqCPPwMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/index.vue?macro=true";
import { default as plansTlwvGDPzKAMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/plans.vue?macro=true";
import { default as update_45carddFTkVl6JMRMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/update-card.vue?macro=true";
import { default as editHIeTPL8SQ0Meta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/[id]/edit.vue?macro=true";
import { default as indexjE4PC6jiEeMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/[id]/index.vue?macro=true";
import { default as create_45newb7QhgUbsqNMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/create-new.vue?macro=true";
import { default as indexOItVk7gqYfMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/index.vue?macro=true";
import { default as redeemXSZVpiDmqsMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/redeem.vue?macro=true";
import { default as custom_45productsnT2DmX5SpoMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/custom-products.vue?macro=true";
import { default as custom_45servicesPWTYtMtC69Meta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/custom-services.vue?macro=true";
import { default as editxRs6PVeZSOMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/edit.vue?macro=true";
import { default as galleryggAyUzBtCgMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/gallery.vue?macro=true";
import { default as general_45productskrOeUVUMmEMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/general-products.vue?macro=true";
import { default as general_45servicesCiizYg8KxPMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/general-services.vue?macro=true";
import { default as indexryFOeoWt3qMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/index.vue?macro=true";
import { default as posts4oqKc108b2Meta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/posts.vue?macro=true";
import { default as reviewsknVLJo5x4FMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/reviews.vue?macro=true";
import { default as salesBObszIew1mMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/sales.vue?macro=true";
import { default as timingRg3Rl6zlczMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/timing.vue?macro=true";
import { default as availed_45couponsiHOei81DGRMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/availed-coupons.vue?macro=true";
import { default as create_45newdt2I72mWfqMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/create-new.vue?macro=true";
import { default as indexSSIaXsQUDqMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/index.vue?macro=true";
import { default as liked_45businesses1fFG816DcnMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/liked-businesses.vue?macro=true";
import { default as reviewst3MTTCdRLFMeta } from "/var/www/alahddin-fend/pages/dashboard/businesses/reviews.vue?macro=true";
import { default as edithM2MTE5F6jMeta } from "/var/www/alahddin-fend/pages/dashboard/classified/[slug]/edit.vue?macro=true";
import { default as galleryhRh6rgn4sZMeta } from "/var/www/alahddin-fend/pages/dashboard/classified/[slug]/gallery.vue?macro=true";
import { default as indexnJmyg8e0wZMeta } from "/var/www/alahddin-fend/pages/dashboard/classified/[slug]/index.vue?macro=true";
import { default as ordersDLh7Ma0dO8Meta } from "/var/www/alahddin-fend/pages/dashboard/classified/[slug]/orders.vue?macro=true";
import { default as create_45newa8S2mlgLAXMeta } from "/var/www/alahddin-fend/pages/dashboard/classified/create-new.vue?macro=true";
import { default as index1hlxPn44oRMeta } from "/var/www/alahddin-fend/pages/dashboard/classified/index.vue?macro=true";
import { default as saved_45adsiiSNupODFXMeta } from "/var/www/alahddin-fend/pages/dashboard/classified/saved-ads.vue?macro=true";
import { default as donationsqtDmFcqfVqMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/donations.vue?macro=true";
import { default as editrRMtiC15NlMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/edit.vue?macro=true";
import { default as galleryMmAJsV0yQRMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/gallery.vue?macro=true";
import { default as indexiiaOYGPlhwMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/index.vue?macro=true";
import { default as timeline_45updates295pp52BuuMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/timeline-updates.vue?macro=true";
import { default as create_45newJWY4XlwR6fMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/create-new.vue?macro=true";
import { default as index0ov8yK0vkJMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/index.vue?macro=true";
import { default as my_45donationsJ6hrTStOOwMeta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/my-donations.vue?macro=true";
import { default as saved_45campaignsFCiUnYBdA2Meta } from "/var/www/alahddin-fend/pages/dashboard/fundraising/saved-campaigns.vue?macro=true";
import { default as indexicqrGbvteiMeta } from "/var/www/alahddin-fend/pages/dashboard/index.vue?macro=true";
import { default as edit7o6EM9uoLOMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/edit.vue?macro=true";
import { default as eid_45al_45adha_45prayer_45timingEinuus8LBFMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/eid-al-adha-prayer-timing.vue?macro=true";
import { default as eid_45al_45fitr_45prayer_45timingCWUBmmKY1TMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/eid-al-fitr-prayer-timing.vue?macro=true";
import { default as galleryY2XtW0NO53Meta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/gallery.vue?macro=true";
import { default as indexthMlNDaO5XMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/index.vue?macro=true";
import { default as jummah_45prayer_45timingQL78urmgH1Meta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/jummah-prayer-timing.vue?macro=true";
import { default as prayer_45timing3Zfqvlx3yoMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/prayer-timing.vue?macro=true";
import { default as create_45newj3Lb7UKSjnMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/create-new.vue?macro=true";
import { default as index4GtQou3MouMeta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/index.vue?macro=true";
import { default as subscribed_45islamic_45centersLTou9Wb0x7Meta } from "/var/www/alahddin-fend/pages/dashboard/islamic-centers/subscribed-islamic-centers.vue?macro=true";
import { default as editpQ9kK7qhTEMeta } from "/var/www/alahddin-fend/pages/dashboard/news/[slug]/edit.vue?macro=true";
import { default as galleryCpD9E8Mj8AMeta } from "/var/www/alahddin-fend/pages/dashboard/news/[slug]/gallery.vue?macro=true";
import { default as indexOTCT0pXBQjMeta } from "/var/www/alahddin-fend/pages/dashboard/news/[slug]/index.vue?macro=true";
import { default as ordersg45g1vK4rXMeta } from "/var/www/alahddin-fend/pages/dashboard/news/[slug]/orders.vue?macro=true";
import { default as create_45newI29MnmGndXMeta } from "/var/www/alahddin-fend/pages/dashboard/news/create-new.vue?macro=true";
import { default as indexQxx1Ssv8JJMeta } from "/var/www/alahddin-fend/pages/dashboard/news/index.vue?macro=true";
import { default as pinned_45newsTSs9zlc5rKMeta } from "/var/www/alahddin-fend/pages/dashboard/news/pinned-news.vue?macro=true";
import { default as profileyddlJDpgZ2Meta } from "/var/www/alahddin-fend/pages/dashboard/profile.vue?macro=true";
import { default as indexOnhEs410FRMeta } from "/var/www/alahddin-fend/pages/forgot-password/index.vue?macro=true";
import { default as reset99LHk8B8vnMeta } from "/var/www/alahddin-fend/pages/forgot-password/reset.vue?macro=true";
import { default as index4ofvIRfUQAMeta } from "/var/www/alahddin-fend/pages/fundraising/[slug]/donate/index.vue?macro=true";
import { default as thank_45youLyYv41eDDAMeta } from "/var/www/alahddin-fend/pages/fundraising/[slug]/donate/thank-you.vue?macro=true";
import { default as indexiLOqe2NUctMeta } from "/var/www/alahddin-fend/pages/fundraising/[slug]/index.vue?macro=true";
import { default as indexMuu95FezCjMeta } from "/var/www/alahddin-fend/pages/fundraising/index.vue?macro=true";
import { default as search1MUpPCc5QxMeta } from "/var/www/alahddin-fend/pages/fundraising/search.vue?macro=true";
import { default as supportP3mbKxsxbgMeta } from "/var/www/alahddin-fend/pages/help/support.vue?macro=true";
import { default as indexkCrD3dzpoxMeta } from "/var/www/alahddin-fend/pages/index.vue?macro=true";
import { default as indexCQWuQHqAReMeta } from "/var/www/alahddin-fend/pages/islamic-centers/[slug]/index.vue?macro=true";
import { default as index5CADRahLNSMeta } from "/var/www/alahddin-fend/pages/islamic-centers/index.vue?macro=true";
import { default as searchEW7gaim61DMeta } from "/var/www/alahddin-fend/pages/islamic-centers/search.vue?macro=true";
import { default as terms_45of_45useQEb5ydIjyxMeta } from "/var/www/alahddin-fend/pages/legal/terms-of-use.vue?macro=true";
import { default as loginnAq30wZGwhMeta } from "/var/www/alahddin-fend/pages/login.vue?macro=true";
import { default as indexPVgGsVmmfAMeta } from "/var/www/alahddin-fend/pages/news/announcements/[slug]/index.vue?macro=true";
import { default as indexZ5VJLvsC6IMeta } from "/var/www/alahddin-fend/pages/news/announcements/index.vue?macro=true";
import { default as searchrVRR6Yu2T9Meta } from "/var/www/alahddin-fend/pages/news/announcements/search.vue?macro=true";
import { default as indexzvjvqMdsbHMeta } from "/var/www/alahddin-fend/pages/news/index.vue?macro=true";
import { default as indexN5pY6UoO5IMeta } from "/var/www/alahddin-fend/pages/news/stories/[slug]/index.vue?macro=true";
import { default as indexlHNgomzbLXMeta } from "/var/www/alahddin-fend/pages/news/stories/index.vue?macro=true";
import { default as cookie_45policy3kmvQlGyeEMeta } from "/var/www/alahddin-fend/pages/policies/cookie-policy.vue?macro=true";
import { default as privacy_45policyhLVCNi8E5UMeta } from "/var/www/alahddin-fend/pages/policies/privacy-policy.vue?macro=true";
import { default as registerUV2WZhWIBrMeta } from "/var/www/alahddin-fend/pages/register.vue?macro=true";
import { default as index8eFJSUETNBMeta } from "/var/www/alahddin-fend/pages/users/[id]/index.vue?macro=true";
import { default as verify_45emailJqnbJj38vQMeta } from "/var/www/alahddin-fend/pages/verify-email.vue?macro=true";
import { default as verify_45your_45emailIou5uK5tDsMeta } from "/var/www/alahddin-fend/pages/verify-your-email.vue?macro=true";
export default [
  {
    name: "businesses-slug-avail-coupon-id",
    path: "/businesses/:slug()/avail-coupon/:id()",
    meta: _91id_93JJNH8BNQcsMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/businesses/[slug]/avail-coupon/[id].vue")
  },
  {
    name: "businesses-slug",
    path: "/businesses/:slug()",
    meta: indexAqIOeRSTf6Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/businesses/[slug]/index.vue")
  },
  {
    name: "businesses",
    path: "/businesses",
    meta: indexLAk2OzypsDMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/businesses/index.vue")
  },
  {
    name: "businesses-search",
    path: "/businesses/search",
    meta: searchTzI7gE75r4Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/businesses/search.vue")
  },
  {
    name: "classified-slug",
    path: "/classified/:slug()",
    meta: indexnRmazA3OkgMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/classified/[slug]/index.vue")
  },
  {
    name: "classified",
    path: "/classified",
    meta: indexkGedY9Z07uMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/classified/index.vue")
  },
  {
    name: "classified-search",
    path: "/classified/search",
    meta: search3KUeGOXDFHMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/classified/search.vue")
  },
  {
    name: "dashboard-businesses-slug-amenities",
    path: "/dashboard/businesses/:slug()/amenities",
    meta: amenities5NpY1nOEapMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/amenities.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-checkout",
    path: "/dashboard/businesses/:slug()/billing/checkout",
    meta: checkouthzT0aycgmwMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/checkout.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-downgrade",
    path: "/dashboard/businesses/:slug()/billing/downgrade",
    meta: downgradePuN5FhAXaEMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/downgrade.vue")
  },
  {
    name: "dashboard-businesses-slug-billing",
    path: "/dashboard/businesses/:slug()/billing",
    meta: indexOL9rEqCPPwMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/index.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-plans",
    path: "/dashboard/businesses/:slug()/billing/plans",
    meta: plansTlwvGDPzKAMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/plans.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-update-card",
    path: "/dashboard/businesses/:slug()/billing/update-card",
    meta: update_45carddFTkVl6JMRMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/billing/update-card.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-id-edit",
    path: "/dashboard/businesses/:slug()/coupons/:id()/edit",
    meta: editHIeTPL8SQ0Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/[id]/edit.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-id",
    path: "/dashboard/businesses/:slug()/coupons/:id()",
    meta: indexjE4PC6jiEeMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/[id]/index.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-create-new",
    path: "/dashboard/businesses/:slug()/coupons/create-new",
    meta: create_45newb7QhgUbsqNMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/create-new.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons",
    path: "/dashboard/businesses/:slug()/coupons",
    meta: indexOItVk7gqYfMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/index.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-redeem",
    path: "/dashboard/businesses/:slug()/coupons/redeem",
    meta: redeemXSZVpiDmqsMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/coupons/redeem.vue")
  },
  {
    name: "dashboard-businesses-slug-custom-products",
    path: "/dashboard/businesses/:slug()/custom-products",
    meta: custom_45productsnT2DmX5SpoMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/custom-products.vue")
  },
  {
    name: "dashboard-businesses-slug-custom-services",
    path: "/dashboard/businesses/:slug()/custom-services",
    meta: custom_45servicesPWTYtMtC69Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/custom-services.vue")
  },
  {
    name: "dashboard-businesses-slug-edit",
    path: "/dashboard/businesses/:slug()/edit",
    meta: editxRs6PVeZSOMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/edit.vue")
  },
  {
    name: "dashboard-businesses-slug-gallery",
    path: "/dashboard/businesses/:slug()/gallery",
    meta: galleryggAyUzBtCgMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/gallery.vue")
  },
  {
    name: "dashboard-businesses-slug-general-products",
    path: "/dashboard/businesses/:slug()/general-products",
    meta: general_45productskrOeUVUMmEMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/general-products.vue")
  },
  {
    name: "dashboard-businesses-slug-general-services",
    path: "/dashboard/businesses/:slug()/general-services",
    meta: general_45servicesCiizYg8KxPMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/general-services.vue")
  },
  {
    name: "dashboard-businesses-slug",
    path: "/dashboard/businesses/:slug()",
    meta: indexryFOeoWt3qMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/index.vue")
  },
  {
    name: "dashboard-businesses-slug-posts",
    path: "/dashboard/businesses/:slug()/posts",
    meta: posts4oqKc108b2Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/posts.vue")
  },
  {
    name: "dashboard-businesses-slug-reviews",
    path: "/dashboard/businesses/:slug()/reviews",
    meta: reviewsknVLJo5x4FMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/reviews.vue")
  },
  {
    name: "dashboard-businesses-slug-sales",
    path: "/dashboard/businesses/:slug()/sales",
    meta: salesBObszIew1mMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/sales.vue")
  },
  {
    name: "dashboard-businesses-slug-timing",
    path: "/dashboard/businesses/:slug()/timing",
    meta: timingRg3Rl6zlczMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/[slug]/timing.vue")
  },
  {
    name: "dashboard-businesses-availed-coupons",
    path: "/dashboard/businesses/availed-coupons",
    meta: availed_45couponsiHOei81DGRMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/availed-coupons.vue")
  },
  {
    name: "dashboard-businesses-create-new",
    path: "/dashboard/businesses/create-new",
    meta: create_45newdt2I72mWfqMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/create-new.vue")
  },
  {
    name: "dashboard-businesses",
    path: "/dashboard/businesses",
    meta: indexSSIaXsQUDqMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/index.vue")
  },
  {
    name: "dashboard-businesses-liked-businesses",
    path: "/dashboard/businesses/liked-businesses",
    meta: liked_45businesses1fFG816DcnMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/liked-businesses.vue")
  },
  {
    name: "dashboard-businesses-reviews",
    path: "/dashboard/businesses/reviews",
    meta: reviewst3MTTCdRLFMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/businesses/reviews.vue")
  },
  {
    name: "dashboard-classified-slug-edit",
    path: "/dashboard/classified/:slug()/edit",
    meta: edithM2MTE5F6jMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/[slug]/edit.vue")
  },
  {
    name: "dashboard-classified-slug-gallery",
    path: "/dashboard/classified/:slug()/gallery",
    meta: galleryhRh6rgn4sZMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/[slug]/gallery.vue")
  },
  {
    name: "dashboard-classified-slug",
    path: "/dashboard/classified/:slug()",
    meta: indexnJmyg8e0wZMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/[slug]/index.vue")
  },
  {
    name: "dashboard-classified-slug-orders",
    path: "/dashboard/classified/:slug()/orders",
    meta: ordersDLh7Ma0dO8Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/[slug]/orders.vue")
  },
  {
    name: "dashboard-classified-create-new",
    path: "/dashboard/classified/create-new",
    meta: create_45newa8S2mlgLAXMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/create-new.vue")
  },
  {
    name: "dashboard-classified",
    path: "/dashboard/classified",
    meta: index1hlxPn44oRMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/index.vue")
  },
  {
    name: "dashboard-classified-saved-ads",
    path: "/dashboard/classified/saved-ads",
    meta: saved_45adsiiSNupODFXMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/classified/saved-ads.vue")
  },
  {
    name: "dashboard-fundraising-slug-donations",
    path: "/dashboard/fundraising/:slug()/donations",
    meta: donationsqtDmFcqfVqMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/donations.vue")
  },
  {
    name: "dashboard-fundraising-slug-edit",
    path: "/dashboard/fundraising/:slug()/edit",
    meta: editrRMtiC15NlMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/edit.vue")
  },
  {
    name: "dashboard-fundraising-slug-gallery",
    path: "/dashboard/fundraising/:slug()/gallery",
    meta: galleryMmAJsV0yQRMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/gallery.vue")
  },
  {
    name: "dashboard-fundraising-slug",
    path: "/dashboard/fundraising/:slug()",
    meta: indexiiaOYGPlhwMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/index.vue")
  },
  {
    name: "dashboard-fundraising-slug-timeline-updates",
    path: "/dashboard/fundraising/:slug()/timeline-updates",
    meta: timeline_45updates295pp52BuuMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/[slug]/timeline-updates.vue")
  },
  {
    name: "dashboard-fundraising-create-new",
    path: "/dashboard/fundraising/create-new",
    meta: create_45newJWY4XlwR6fMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/create-new.vue")
  },
  {
    name: "dashboard-fundraising",
    path: "/dashboard/fundraising",
    meta: index0ov8yK0vkJMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/index.vue")
  },
  {
    name: "dashboard-fundraising-my-donations",
    path: "/dashboard/fundraising/my-donations",
    meta: my_45donationsJ6hrTStOOwMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/my-donations.vue")
  },
  {
    name: "dashboard-fundraising-saved-campaigns",
    path: "/dashboard/fundraising/saved-campaigns",
    meta: saved_45campaignsFCiUnYBdA2Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/fundraising/saved-campaigns.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexicqrGbvteiMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-edit",
    path: "/dashboard/islamic-centers/:slug()/edit",
    meta: edit7o6EM9uoLOMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/edit.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-eid-al-adha-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/eid-al-adha-prayer-timing",
    meta: eid_45al_45adha_45prayer_45timingEinuus8LBFMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/eid-al-adha-prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-eid-al-fitr-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/eid-al-fitr-prayer-timing",
    meta: eid_45al_45fitr_45prayer_45timingCWUBmmKY1TMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/eid-al-fitr-prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-gallery",
    path: "/dashboard/islamic-centers/:slug()/gallery",
    meta: galleryY2XtW0NO53Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/gallery.vue")
  },
  {
    name: "dashboard-islamic-centers-slug",
    path: "/dashboard/islamic-centers/:slug()",
    meta: indexthMlNDaO5XMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/index.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-jummah-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/jummah-prayer-timing",
    meta: jummah_45prayer_45timingQL78urmgH1Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/jummah-prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/prayer-timing",
    meta: prayer_45timing3Zfqvlx3yoMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/[slug]/prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-create-new",
    path: "/dashboard/islamic-centers/create-new",
    meta: create_45newj3Lb7UKSjnMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/create-new.vue")
  },
  {
    name: "dashboard-islamic-centers",
    path: "/dashboard/islamic-centers",
    meta: index4GtQou3MouMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/index.vue")
  },
  {
    name: "dashboard-islamic-centers-subscribed-islamic-centers",
    path: "/dashboard/islamic-centers/subscribed-islamic-centers",
    meta: subscribed_45islamic_45centersLTou9Wb0x7Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/islamic-centers/subscribed-islamic-centers.vue")
  },
  {
    name: "dashboard-news-slug-edit",
    path: "/dashboard/news/:slug()/edit",
    meta: editpQ9kK7qhTEMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/[slug]/edit.vue")
  },
  {
    name: "dashboard-news-slug-gallery",
    path: "/dashboard/news/:slug()/gallery",
    meta: galleryCpD9E8Mj8AMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/[slug]/gallery.vue")
  },
  {
    name: "dashboard-news-slug",
    path: "/dashboard/news/:slug()",
    meta: indexOTCT0pXBQjMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/[slug]/index.vue")
  },
  {
    name: "dashboard-news-slug-orders",
    path: "/dashboard/news/:slug()/orders",
    meta: ordersg45g1vK4rXMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/[slug]/orders.vue")
  },
  {
    name: "dashboard-news-create-new",
    path: "/dashboard/news/create-new",
    meta: create_45newI29MnmGndXMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/create-new.vue")
  },
  {
    name: "dashboard-news",
    path: "/dashboard/news",
    meta: indexQxx1Ssv8JJMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/index.vue")
  },
  {
    name: "dashboard-news-pinned-news",
    path: "/dashboard/news/pinned-news",
    meta: pinned_45newsTSs9zlc5rKMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/news/pinned-news.vue")
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    meta: profileyddlJDpgZ2Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/dashboard/profile.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexOnhEs410FRMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-reset",
    path: "/forgot-password/reset",
    meta: reset99LHk8B8vnMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/forgot-password/reset.vue")
  },
  {
    name: "fundraising-slug-donate",
    path: "/fundraising/:slug()/donate",
    meta: index4ofvIRfUQAMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/fundraising/[slug]/donate/index.vue")
  },
  {
    name: "fundraising-slug-donate-thank-you",
    path: "/fundraising/:slug()/donate/thank-you",
    meta: thank_45youLyYv41eDDAMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/fundraising/[slug]/donate/thank-you.vue")
  },
  {
    name: "fundraising-slug",
    path: "/fundraising/:slug()",
    meta: indexiLOqe2NUctMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/fundraising/[slug]/index.vue")
  },
  {
    name: "fundraising",
    path: "/fundraising",
    meta: indexMuu95FezCjMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/fundraising/index.vue")
  },
  {
    name: "fundraising-search",
    path: "/fundraising/search",
    meta: search1MUpPCc5QxMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/fundraising/search.vue")
  },
  {
    name: "help-support",
    path: "/help/support",
    meta: supportP3mbKxsxbgMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/help/support.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexkCrD3dzpoxMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/index.vue")
  },
  {
    name: "islamic-centers-slug",
    path: "/islamic-centers/:slug()",
    meta: indexCQWuQHqAReMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/islamic-centers/[slug]/index.vue")
  },
  {
    name: "islamic-centers",
    path: "/islamic-centers",
    meta: index5CADRahLNSMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/islamic-centers/index.vue")
  },
  {
    name: "islamic-centers-search",
    path: "/islamic-centers/search",
    meta: searchEW7gaim61DMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/islamic-centers/search.vue")
  },
  {
    name: "legal-terms-of-use",
    path: "/legal/terms-of-use",
    meta: terms_45of_45useQEb5ydIjyxMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/legal/terms-of-use.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginnAq30wZGwhMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/login.vue")
  },
  {
    name: "news-announcements-slug",
    path: "/news/announcements/:slug()",
    meta: indexPVgGsVmmfAMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/news/announcements/[slug]/index.vue")
  },
  {
    name: "news-announcements",
    path: "/news/announcements",
    meta: indexZ5VJLvsC6IMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/news/announcements/index.vue")
  },
  {
    name: "news-announcements-search",
    path: "/news/announcements/search",
    meta: searchrVRR6Yu2T9Meta || {},
    component: () => import("/var/www/alahddin-fend/pages/news/announcements/search.vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexzvjvqMdsbHMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/news/index.vue")
  },
  {
    name: "news-stories-slug",
    path: "/news/stories/:slug()",
    meta: indexN5pY6UoO5IMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/news/stories/[slug]/index.vue")
  },
  {
    name: "news-stories",
    path: "/news/stories",
    meta: indexlHNgomzbLXMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/news/stories/index.vue")
  },
  {
    name: "policies-cookie-policy",
    path: "/policies/cookie-policy",
    meta: cookie_45policy3kmvQlGyeEMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/policies/cookie-policy.vue")
  },
  {
    name: "policies-privacy-policy",
    path: "/policies/privacy-policy",
    meta: privacy_45policyhLVCNi8E5UMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/policies/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerUV2WZhWIBrMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/register.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: index8eFJSUETNBMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/users/[id]/index.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: verify_45emailJqnbJj38vQMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/verify-email.vue")
  },
  {
    name: "verify-your-email",
    path: "/verify-your-email",
    meta: verify_45your_45emailIou5uK5tDsMeta || {},
    component: () => import("/var/www/alahddin-fend/pages/verify-your-email.vue")
  }
]