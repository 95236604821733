<template>
  <div
      class="flex flex-col justify-center items-center w-full m-0 p-0 z-40 sticky top-0 bg-transparent"
  >
    <!-- Top Bar -->
    <div class="flex flex-none w-full h-20 pt-2 px-0 md:px-2 lg:px-6 bg-white">
      <div
          class="flex items-center justify-evenly w-full max-w-screen-2xl mx-auto pt-2"
      >
        <!-- Brand Starts -->
        <nuxt-link
            :to="{ name: 'news' }"
            class="flex flex-col w-2/12 pl-2 items-start justify-center cursor-pointer"
        >
          <NuxtImg
              src="/images/main/logo.png"
              alt=""
              class="object-contain"
              height="40"
          />
          <p
              class="text-sm mx-auto font-semibold tracking-widest"
          >
            News
          </p>
        </nuxt-link>
        <ExploreDropDown/>
        <!-- Brand Ends-->
        <!-- Search Section -->
        <div class="flex flex-row w-6/12 py-2 px-4 items-center justify-center">
          <!-- Search Bar -->

          <NewsInstantSearch />
          <!-- Search Bar Ends -->
        </div>
        <!-- Search Section Ends -->
        <!-- Right Extra Section -->
        <div class="flex flex-row-reverse w-4/12 items-center justify-start">
          <!-- User Dropdown -->
          <BaseUserDropdown v-if="isLoggedIn" />
          <!-- User Dropdown Ends -->
          <div class="flex flex-row items-center justify-center" v-else>
            <nuxt-link
                :to="{ name: 'login' }"
                class="mx-1 px-1 hover:text-a-secondary font-semibold text-sm"
            >
              Log In
            </nuxt-link>
            <BaseVerticalDivider color-class="border-a-neutral-dim" />
            <BaseButton
            primary-color-class="a-secondary"
            hovered-background-color-class="a-secondary-dark"
            text-size-class="text-sm"
            :is-slim="false"
            text-color-class="white"
            @click.native="useRouter().push({ name: 'register' })"
            >
                Get Started
            </BaseButton>
          </div>
          <BaseButton
              margin-classes="mx-2"
              primary-color-class="a-primary"
              hovered-text-color-class="a-neutral-darkest"
              text-size-class="text-sm"
              font-weight-class="font-medium"
              width-class="w-auto"
              text-color-class="hover:text-a-neutral-darkest"
              is-outlined
              :is-slim="false"
              @click.native="
            gotoCreateNewsAnnouncementPage
          "
          >
            <Icon
                name="mdi:plus"
            />
            Announce
          </BaseButton>
          <nuxt-link
              :to="{ name: 'help-support' }"
              class="mx-1 px-1 hover:text-a-secondary font-semibold text-sm"
          >
            Help
          </nuxt-link>
        </div>
        <!-- Right Extra Section Ends -->
      </div>
    </div>
    <!-- Top Bar Ends-->
    <!-- English News Bar-->
    <NewsTickerBar
        :barTitle="'Breaking News'"
        :newsTickers="newsTickers as NewsTicker[]"
        :property="'eng_details'"
        :swiperName="'english-news-swiper'"
    />
    <!-- English News Bar Ends-->
    <!-- Urdu News Bar-->
    <NewsTickerBar
        :barTitle="'تازہ خبریں'"
        :newsTickers="newsTickers as NewsTicker[]"
        :property="'urdu_details'"
        :isUrdu="true"
        :swiperName="'urdu-news-swiper'"
    />
    <!-- Urdu News Bar Ends-->
    <!-- Main News Alert -->
    <BaseAlert alignText="center" class="z-50" />
    <!-- Main News Alert Ends -->
  </div>
</template>

<script setup lang="ts">
import {useMainNewsDataManager} from "~/composables/useMainNewsDataManager";
import type {NewsTicker} from "~/types";
import {useAuthManager} from "#imports";

const showCategoriesDropdown = ref(false);
const newsTickers = await useMainNewsDataManager().getMainNewsTickers();

function viewNewsPost(newsPostId) {
  navigateTo("/news/posts/" + newsPostId);
}

function gotoNewsHome() {
  navigateTo("/news");
}

function gotoCreateNewsAnnouncementPage() {
  navigateTo({
    name: "dashboard-news-create-new",
  });
}

const isLoggedIn = computed(()=>{
  return useAuthManager().isLoggedIn.value
})

</script>

<style></style>
