<template>
  <div class="h-full mx-3"
  :class="[colorClass, borderWidthClass,minHeightClass ]"
  ></div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  colorClass: String,
  minHeightClass: String,
  borderWidth: Number,
}>(), {
  colorClass: 'border-blue-500',
  minHeightClass: 'min-h-6',
  borderWidth: 2,
})

const borderWidthClass = computed(()=>{
  if(props.borderWidth === 2){
    return 'border-l-2'
  }
  else if(props.borderWidth === 3){
    return 'border-l-3'
  }
  else if(props.borderWidth === 4){
    return 'border-l-4'
  }else{
    return 'border-l-1'
  }
})
</script>
