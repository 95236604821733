<template>
  <div class="h-screen text-a-neutral font-sans tracking-wide overflow-hidden">
    <DashboardNav class="hidden md:flex" />
    <DashboardNavMobile class="flex md:hidden" />
    <div class="flex flex-row w-full h-full">
      <div class="w-2/12 hidden md:flex flex-none md:w-2/12 pt-8 border-r-2 h-screen">
        <BaseDashboardSideMenu
          :menuItems="menuItems"
          dashboardPathName="dashboard-classified"
        >
          <template v-slot:actionButton>
            <BaseButton
            primary-color-class="a-neutral-dim"
              text-color-class="a-neutral-dark"
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
          
              @click="showDeactivationModal()"
              v-if="classifiedAd && classifiedAd?.status != 'Deactivated'"
            >
              <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:archive-remove-outline"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold">Deactivate</span>
              </span> 
            </BaseButton>
          </template>
        </BaseDashboardSideMenu>
      </div>
      <div class="flex md:hidden">
        <BaseDashboardSideMenuMobile
          :menuItems="menuItems"
          dashboardPathName="dashboard-classified"
        >

          <template v-slot:actionButton>
            <BaseButton
            primary-color-class="a-neutral-dim"
              text-color-class="a-neutral-dark"
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
              @click="showDeactivationModal()"
              v-if="classifiedAd && classifiedAd?.status != 'Deactivated'"
            >
            <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:archive-remove-outline"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold">Deactivate</span>
              </span> 
            </BaseButton>
          </template>

        </BaseDashboardSideMenuMobile>
      </div>

      <div class="flex flex-col w-full pb-48 md:w-10/12 pt-8 overflow-y-auto bg-a-background-light-primary">
        <slot :key="route.fullPath" />
      </div>
      <BaseModal ref="classifiedAdDeactivationModalRef">
        <DashboardClassifiedAdDeactivationModalTemplate
          :classified-ad="classifiedAd"
          @closeModal="hideDeactivationModal()"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseModal } from '~/types';
import type {DashboardMenuItem} from "~/types/dashboard";

const route = useRoute();

const menuItems = ref<DashboardMenuItem[]>([
  {
    title: "All Details",
    link: {
      routeName: "dashboard-classified-slug",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:menu",
  },
  {
    title: "Edit",
    link: {
      routeName: "dashboard-classified-slug-edit",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:clipboard-edit-outline",
  },
  {
    title: "Gallery",
    link: {
      routeName: "dashboard-classified-slug-gallery",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:image-album",
  },
  {
    title: "Featured Orders",
    link: {
      routeName: "dashboard-classified-slug-orders",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:package-variant-closed",
  },
]);

const classifiedAd = await useClassifiedDataManager().getCurrentDashboardClassifiedAd();
const key = computed(() => {
  return useRoute().fullPath;
});

watch(() => route.params.slug, () => {
  menuItems.value.forEach(
    (menuItem) => (menuItem.link.routeParams.slug = classifiedAd.value?.slug as string)
  );
},
);

const classifiedAdDeactivationModalRef = ref<BaseModal | null>(null);

function showDeactivationModal() {
  if (classifiedAdDeactivationModalRef.value) {
    classifiedAdDeactivationModalRef.value.show();
  }
}
function hideDeactivationModal() {
  if (classifiedAdDeactivationModalRef.value) {
    classifiedAdDeactivationModalRef.value.hide();
  }
}
</script>

<style></style>
