import {defineStore} from "pinia";
import defaultLocations from "~/data/default_locations.json";
import type {PriceRangeSelection, SearchLocation, SearchSortSelection} from "~/types";

interface SearchState {
    current_search_location: SearchLocation | null,
    default_well_known_locations: object[],
    is_searching: boolean,
    current_search_query: string,
    is_showing_search_suggestions: boolean,
    //Classified
    classified_search_sort_selections: SearchSortSelection[],
    current_classified_sort_filter_id: number,
    radius_selections: number[]
    current_search_radius: number,
    classified_search_price_range_selections: PriceRangeSelection[],
    current_classified_price_range_filter_id: number,
    current_classified_categories_filter: string[] | null,
    //Fundraising
    fundraising_search_sort_selections: SearchSortSelection[],
    current_fundraising_sort_filter_id: number,
    current_fundraising_type_filter: string[],
    current_fundraising_categories_filter: string[],
    //News
    news_search_sort_selections: SearchSortSelection[],
    current_news_sort_filter_id: number,
    current_news_categories_filter: string[],
    //Businesses
    businesses_search_sort_selections: SearchSortSelection[],
    current_businesses_sort_filter_id: number,
    current_businesses_categories_filter: string[],
    //IslamicCenters
    is_madrassah_filter: boolean | null,
    is_school_filter: boolean | null,
    offers_funeral_services_filter: boolean | null,
    accepts_zakat_filter: boolean | null,
    accepts_sadaqah_filter: boolean | null,
    accepts_khairat_filter: boolean | null,
    accepts_general_donation_filter: boolean | null,
}

export const useSearch = defineStore('search', {
    state: (): SearchState => {
        return {
            current_search_location: {
                id: "place.9607189446701850",
                type: "Feature",
                place_type: ["place"],
                relevance: 1,
                properties: {wikidata: "Q1297"},
                text: "Chicago",
                place_name: "Chicago, Illinois, United States",
                bbox: [-87.931085223, 41.625740009, -87.507792006, 42.023137],
                center: [-87.6244, 41.8756],
                geometry: {type: "Point", coordinates: [-87.6244, 41.8756]},
                context: [
                    {
                        id: "district.8754923997749290",
                        wikidata: "Q108418",
                        text: "Cook County"
                    },
                    {
                        id: "region.10854263468358810",
                        wikidata: "Q1204",
                        short_code: "US-IL",
                        text: "Illinois"
                    },
                    {
                        id: "country.19678805456372290",
                        wikidata: "Q30",
                        short_code: "us",
                        text: "United States"
                    }
                ]
            },
            default_well_known_locations: defaultLocations,
            is_searching: false,
            current_search_query: '',
            is_showing_search_suggestions: false,
            classified_search_sort_selections: [{
                id: 1,
                title: "Latest",
                icon: "mdi:sort-calendar-descending",
                sort_by: "latest"
            },
                {
                    id: 2,
                    title: "Oldest",
                    icon: "mdi:sort-calendar-ascending",
                    sort_by: "oldest"
                },
                {
                    id: 3,
                    title: "Lowest Price",
                    icon: "mdi:trending-down",
                    sort_by: "lowest-price"
                },
                {
                    id: 4,
                    title: "Highest Price",
                    icon: "mdi:trending-up",
                    sort_by: "highest-price"
                }],
            current_classified_sort_filter_id: 1,
            radius_selections: [
                2,
                1,
                5,
                10,
                15,
                25,
                50,
                100,
                300,
                600,
                1000,
                1200,
                1300,
                1500,
                1800,
                5000,
            ],
            current_search_radius: 100,
            classified_search_price_range_selections: [
                {
                    id: 1,
                    min: null,
                    max: null
                },
                {
                    id: 2,
                    min: 0,
                    max: 100
                },
                {
                    id: 3,
                    min: 100,
                    max: 200
                },
                {
                    id: 4,
                    min: 200,
                    max: 500
                },
                {
                    id: 5,
                    min: 500,
                    max: 1000
                },
                {
                    id: 6,
                    min: 1000,
                    max: null
                }
            ],
            current_classified_price_range_filter_id: 1,
            current_classified_categories_filter: null,
            //Fundraising
            fundraising_search_sort_selections: [
                {
                    id: 1,
                    title: "Latest",
                    icon: "mdi:sort-calendar-ascending",
                    sort_by: "latest",
                },
                {
                    id: 2,
                    title: "Oldest",
                    icon: "mdi:sort-calendar-descending",
                    sort_by: "oldest",
                },
                {
                    id: 3,
                    title: "Urgent Need",
                    icon: "mdi:timer",
                    sort_by: "urgent-need",
                },
                {
                    id: 4,
                    title: "Most Donations",
                    icon: "mdi:heart",
                    sort_by: "most-donations",
                },
                {
                    id: 5,
                    title: "Least Donations",
                    icon: "mdi:heart-outline",
                    sort_by: "least-donations",
                },
            ],
            current_fundraising_sort_filter_id: 1,
            current_fundraising_type_filter: [],
            current_fundraising_categories_filter: [],
            //News
            news_search_sort_selections: [
                {
                    id: 1,
                    title: "Latest",
                    icon: "mdi:sort-calendar-ascending",
                    sort_by: "latest",
                },
                {
                    id: 2,
                    title: "Oldest",
                    icon: "mdi:sort-calendar-descending",
                    sort_by: "oldest",
                },
                {
                    id: 3,
                    title: "Most Views",
                    icon: "mdi:eye",
                    sort_by: "most-views",
                },
            ],
            current_news_sort_filter_id: 1,
            current_news_categories_filter: [],
            //Businesses
            businesses_search_sort_selections: [
                {
                    id: 1,
                    title: "Latest",
                    icon: "mdi:sort-calendar-descending",
                    sort_by: "latest",
                },
                {
                    id: 2,
                    title: "Oldest",
                    icon: "mdi:sort-calendar-ascending",
                    sort_by: "oldest",
                },
                {
                    id: 3,
                    title: "Top Rated",
                    icon: "tabler:star-filled",
                    sort_by: "top-rated",
                },
                {
                    id: 4,
                    title: "Least Rated",
                    icon: "tabler:star-half-filled",
                    sort_by: "least-rated",
                },
            ],
            current_businesses_sort_filter_id: 1,
            current_businesses_categories_filter: [],
            //Islamic Centers
            is_madrassah_filter: null,
            is_school_filter: null,
            offers_funeral_services_filter: null,
            accepts_zakat_filter: null,
            accepts_sadaqah_filter: null,
            accepts_khairat_filter: null,
            accepts_general_donation_filter: null,
        }
    },
    actions: {
        getClassifiedDefaultQueryParams() {
            return {
                r: 100,
                prs: 1,
                sort: 'latest',
                l: this.current_search_location?.place_name,
                cp: btoa(
                    `${this.current_search_location?.geometry.coordinates[0]},
                    ${this.current_search_location?.geometry.coordinates[1]}`
                ),
            }
        },
        setCurrentSearchLocation(location: SearchLocation) {
            this.current_search_location = location;
        },
        clearCurrentSearchLocation() {
            this.current_search_location = null;
        },
        isSearching() {
            this.is_searching = true;
        },
        isNotSearching() {
            this.is_searching = false;
        },
        setSearchQuery(classifiedSearchQuery: any) {
            this.current_search_query = classifiedSearchQuery;
        },
        clearSearchQuery() {
            this.current_search_query = "";
        },
        showSearchSuggestions() {
            this.is_showing_search_suggestions = true;
        },
        hideSearchSuggestions() {
            this.is_showing_search_suggestions = false;
        },
        //     Classified
        setCurrentClassifiedSortFilterId(id: number) {
            this.current_classified_sort_filter_id = id;
        },
        getCurrentClassifiedSortFilter() {
            let sortQuery = useRoute().query.sort;
            if (sortQuery != null && sortQuery != '' && sortQuery != undefined) {
                return this.classified_search_sort_selections.filter(selection => {
                    return selection.sort_by == sortQuery;
                })[0];
            }
            return this.classified_search_sort_selections.filter(selection => {
                return selection.id === this.current_classified_sort_filter_id
            })[0];
        },
        setCurrentSearchRadius(radius: number) {
            this.current_search_radius = radius;
        },
        getCurrentSearchRadius() {
            return this.current_search_radius;
        },
        setCurrentClassifiedPriceRangeFilterId(id: number) {
            this.current_classified_price_range_filter_id = id;
        },
        getCurrentClassifiedPriceRangeFilter() {
            let queryPriceRangeSelection = useRoute().query.prs;
            if (queryPriceRangeSelection != null && queryPriceRangeSelection != '' && queryPriceRangeSelection != undefined) {
                return this.classified_search_price_range_selections.filter(selection => {
                    return selection.id.toString() == queryPriceRangeSelection;
                })[0];
            }
            return this.classified_search_price_range_selections.filter(selection => {
                return selection.id === this.current_classified_price_range_filter_id
            })[0];
        },
        setCurrentClassifiedSearchCategoriesFilter(categoriesFilter: string[]) {
            this.current_classified_categories_filter = categoriesFilter;
        },
        getCurrentClassifiedSearchCategoriesFilter() {
            return this.current_classified_categories_filter;
        },
        //Fundraising
        getFundraisingDefaultQueryParams() {
            return {
                sort: 'latest',
            }
        },
        setCurrentFundraisingSortFilterId(id: number) {
            this.current_fundraising_sort_filter_id = id;
        },
        getCurrentFundraisingSortFilter() {
            let sortQuery = useRoute().query.sort;
            if (sortQuery != null && sortQuery != '' && sortQuery != undefined) {
                return this.fundraising_search_sort_selections.filter(selection => {
                    return selection.sort_by == sortQuery;
                })[0];
            }
            return this.fundraising_search_sort_selections.filter(selection => {
                return selection.id === this.current_fundraising_sort_filter_id
            })[0];
        },
        setCurrentFundraisingTypeFilter(typesFilter: string[]) {
            this.current_fundraising_type_filter = typesFilter;
        },
        getCurrentFundraisingTypeFilter() {
            return this.current_fundraising_type_filter;
        },
        setCurrentFundraisingSearchCategoriesFilter(categoriesFilter: string[]) {
            this.current_fundraising_categories_filter = categoriesFilter;
        },
        getCurrentFundraisingSearchCategoriesFilter() {
            return this.current_fundraising_categories_filter;
        },
        //News
        getNewsDefaultQueryParams() {
            return {
                r: 100,
                sort: 'latest',
                l: this.current_search_location?.place_name,
                cp: btoa(
                    `${this.current_search_location?.geometry.coordinates[0]},
                          ${this.current_search_location?.geometry.coordinates[1]}`
                ),
            }
        },
        setCurrentNewsSortFilterId(id: number) {
            this.current_news_sort_filter_id = id;
        },
        getCurrentNewsSortFilter() {
            let sortQuery = useRoute().query.sort;
            if (sortQuery != null && sortQuery != '' && sortQuery != undefined) {
                return this.news_search_sort_selections.filter(selection => {
                    return selection.sort_by == sortQuery;
                })[0];
            }
            return this.news_search_sort_selections.filter(selection => {
                return selection.id === this.current_news_sort_filter_id
            })[0];
        },
        setCurrentNewsSearchCategoriesFilter(categoriesFilter: string[]) {
            this.current_news_categories_filter = categoriesFilter;
        },
        getCurrentNewsSearchCategoriesFilter() {
            return this.current_news_categories_filter;
        },
        //Businesses
        getBusinessesDefaultQueryParams() {
            return {
                r: 100,
                sort: 'latest',
                l: this.current_search_location?.place_name,
                cp: btoa(
                    `${this.current_search_location?.geometry.coordinates[0]},
                          ${this.current_search_location?.geometry.coordinates[1]}`
                ),
            }
        },
        setCurrentBusinessesSortFilterId(id: number) {
            this.current_businesses_sort_filter_id = id;
        },
        getCurrentBusinessesSortFilter() {
            let sortQuery = useRoute().query.sort;
            if (sortQuery != null && sortQuery != '' && sortQuery != undefined) {
                return this.businesses_search_sort_selections.filter(selection => {
                    return selection.sort_by == sortQuery;
                })[0];
            }
            return this.businesses_search_sort_selections.filter(selection => {
                return selection.id === this.current_businesses_sort_filter_id
            })[0];
        },
        setCurrentBusinessesSearchCategoriesFilter(categoriesFilter: string[]) {
            this.current_businesses_categories_filter = categoriesFilter;
        },
        getCurrentBusinessesSearchCategoriesFilter() {
            return this.current_businesses_categories_filter;
        },
        //Islamic Centers
        getIslamicCentersDefaultQueryParams() {
            return {
                r: 100,
                l: this.current_search_location?.place_name,
                cp: btoa(
                    `${this.current_search_location?.geometry.coordinates[0]},
                          ${this.current_search_location?.geometry.coordinates[1]}`
                ),
            }
        },
        setIslamicCentersMadrassahFilter() {
            this.is_madrassah_filter = true;
        },
        unSetIslamicCentersMadrassahFilter() {
            this.is_madrassah_filter = null;
        },
        getCurrentIslamicCentersMadrassahFilter() {
            return this.is_madrassah_filter;
        },
        setIslamicCentersSchoolFilter() {
            this.is_school_filter = true;
        },
        unSetIslamicCentersSchoolFilter() {
            this.is_school_filter = null;
        },
        getCurrentIslamicCentersSchoolFilter() {
            return this.is_school_filter;
        },
        setIslamicCentersFuneralServicesFilter() {
            this.offers_funeral_services_filter = true;
        },
        unSetIslamicCentersFuneralServicesFilter() {
            this.offers_funeral_services_filter = null;
        },
        getCurrentIslamicCentersFuneralServicesFilter() {
            return this.offers_funeral_services_filter;
        },
        setIslamicCentersAcceptsZakatFilter() {
            this.accepts_zakat_filter = true;
        },
        unSetIslamicCentersAcceptsZakatFilter() {
            this.accepts_zakat_filter = null;
        },
        getCurrentIslamicCentersAcceptsZakatFilter() {
            return this.accepts_zakat_filter;
        },
        setIslamicCentersAcceptsSadaqahFilter() {
            this.accepts_sadaqah_filter = true;
        },
        unSetIslamicCentersAcceptsSadaqahFilter() {
            this.accepts_sadaqah_filter = null;
        },
        getCurrentIslamicCentersAcceptsSadaqahFilter() {
            return this.accepts_sadaqah_filter;
        },
        setIslamicCentersAcceptsKhairatFilter() {
            this.accepts_khairat_filter = true;
        },
        unSetIslamicCentersAcceptsKhairatFilter() {
            this.accepts_khairat_filter = null;
        },
        getCurrentIslamicCentersAcceptsKhairatFilter() {
            return this.accepts_khairat_filter;
        },
        setIslamicCentersAcceptsGeneralDonationFilter() {
            this.accepts_general_donation_filter = true;
        },
        unSetIslamicCentersAcceptsGeneralDonationFilter() {
            this.accepts_general_donation_filter = null;
        },
        getCurrentIslamicCentersAcceptsGeneralDonationFilter() {
            return this.accepts_general_donation_filter;
        },
    }
})
