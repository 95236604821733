<template>
  <div class="flex flex-col items-center justify-center">
    <!-- Logo  -->
    <div class="flex flex-row items-center justify-center my-2">
    <NuxtImg
        :src="logoPath"
        width="160"
        class=""
        alt=""
    />
    </div>
    <!-- Logo Ends -->
    <div class="flex flex-row items-center justify-center font-semibold text-xl my-2">
      <p>Everything You Need</p>
    </div>
    <!-- Swiper -->
    <div class="swiper logoSwiper h-16 w-full">
      <div
          class="swiper-wrapper w-full"
      >
        <!-- Slides -->
        <div
            v-for="tagline in taglines"
            :key="tagline"
            class="swiper-slide font-semibold text-2xl text-center"
        >
          {{tagline}}
        </div>
      </div>
    </div>
    <!-- Swiper Ends -->
  </div>
</template>

<script
    setup
    lang="ts"
>
import {Autoplay} from 'swiper/modules';

const taglines = [
  'List Your Business',
  'Find Top Rated Businesses',
  'Grab Best Deals & Discounts',
  'Find Nearby Islamic Centers',
  'Get In Touch With Islamic Community',
  'List Classified Items',
  'Search New & Used Items',
  'Raise Funds For A Cause',
  'Donate For A Cause',
  'View Community Announcements',
  'Latest News Local, Nationwide & International',
]

const logoPath =
    "/images/main/logo.png";


const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.logoSwiper', {
    modules: [Autoplay],
    direction: 'vertical',
    autoplay: {
      delay: 3000,
    },
    speed: "500",
    slidesPerView: "1",
    loop: true,
    effect: 'slide',
    allowTouchMove: "false",
    observer: true
  });
})
</script>

<style></style>
