declare module '#app' {
    interface NuxtApp {
        $getInstantLocationSuggestions(query: string): Promise<any>,
        $getMyDashboardStats(): Promise<any>
        $sendEmailVerificationNotification(): Promise<any>
        $verifyEmailAddressFromUrl(url: string): Promise<any>
        $submitForgotPasswordForm(forgotForm: object): Promise<any>
        $submitResetPasswordForm(resetForm: object): Promise<any>
        $getMyUserProfile(): Promise<any>
        $updateMyUserProfile(userProfileForm: object): Promise<any>
        $getMyUserProfileContact(): Promise<any>
        $updateMyUserProfileContact(contactForm: object): Promise<any>
        $searchLocationsByQuery(query: string): Promise<any>
        $getUserProfileById(id: string): Promise<any>
    }
}

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    const { makeFetchApiCallToUrl } = useMakeFetchApiCall()

    nuxtApp.provide('getInstantSearchResultsForHomeByQuery', async (query: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/instant-search?query=${query}`)
    })

    nuxtApp.provide('getGeneralSupportTicketTypes', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/general-support-ticket-types`)
    })

    nuxtApp.provide('getInstantLocationSuggestions', async (query: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/search-locations/?query=${query}`)
    })

    nuxtApp.provide('searchLocationsByQuery', async (query: string): Promise<any> => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/search-locations?query=${query}`)
    })

    nuxtApp.provide('getMyUserProfile', async (): Promise<any> => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/profile`)
    })

    nuxtApp.provide('updateMyUserProfile', async (userProfileForm: object): Promise<any> => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/profile`, userProfileForm)
    })

    nuxtApp.provide('getMyUserProfileContact', async (): Promise<any> => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/contact`)
    })

    nuxtApp.provide('updateMyUserProfileContact', async (contactForm: object): Promise<any> => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/contact`, contactForm)
    })

    nuxtApp.provide('submitForgotPasswordForm', async (forgotPasswordForm: object): Promise<any> => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/forgot-password`, forgotPasswordForm)
    })

    nuxtApp.provide('submitResetPasswordForm', async (resetPasswordForm: object): Promise<any> => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/reset-password`, resetPasswordForm)
    })

    nuxtApp.provide('getMyDashboardStats', async (): Promise<any> => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/dashboard`)
    })

    nuxtApp.provide('sendEmailVerificationNotification', async (): Promise<any> => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/send-verification-notification`)
    })

    nuxtApp.provide('verifyEmailAddressFromUrl', async (url: string): Promise<any> => {
        return await makeFetchApiCallToUrl('get', url)
    })

    nuxtApp.provide('getUserProfileById', async (id: string): Promise<any> => {
        return await makeFetchApiCallToUrl('get',`${config.public.baseApiUrl}/users/${id}`)
    })

    nuxtApp.provide('getLocationSuggestionsFromCoordinates', async (lng:string,lat:string): Promise<any> => {
        return await makeFetchApiCallToUrl('get',`${config.public.baseApiUrl}/r-search-locations/?lng=${lng}&lat=${lat}`)
    })

})