<template>
  <nav class="flex flex-col w-full h-full min-h-full fixed bg-white px-2">
    <BaseButton
        text-size-class="text-xs xl:text-sm"
        primary-color-class="a-primary"
        margin-classes="mb-4"
        animation-classes="transition-colors duration-500"
        @click.native="gotoDashboardMain"
    >
      <Icon
          name="mdi:desktop-mac-dashboard"
          class="px-0.5 text-xl"
      />
      Dashboard
    </BaseButton>
    <div
        class="flex flex-col w-full"
        v-for="(
        menuItem, index
      ) in    menuItemsWithSubItems   "
    >
      <div
          class="flex flex-row items-center text-a-neutral-light px-4 cursor-pointer select-none"
          @click.prevent="
          menuItem.isAccordianOn =
          !menuItem.isAccordianOn
          "
      >
        <p class="font-semibold">
          {{ menuItem.title }}
        </p>
        <Icon
            class="text-2xl ml-auto"
            :name="menuItem.isAccordianOn
            ? 'tabler:chevron-up'
            : 'tabler:chevron-down'"
        />
      </div>
      <transition name="slideDown">
        <div
            class="flex flex-col px-2"
            v-show="menuItem.isAccordianOn
            "
        >
          <nuxt-link
              v-for="(
                 subMenuItem, index   
            ) in    menuItem.subMenuItems   "
              :key="index"
              :to="{
              name: subMenuItem.link
                .routeName,
              query:
                subMenuItem.link
                  .queryParams,
            }"
              class="hover:bg-a-neutral-dim rounded-md"
          >
            <p class="flex flex-row items-center py-2 text-sm">
              <Icon
                  class="mx-1 text-lg w-2/12"
                  :name="subMenuItem.icon"
              />
              <span class="w-10/12">{{ subMenuItem.title }}</span>
            </p>
          </nuxt-link>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script setup>
const menuItemsWithSubItems = computed(()=>{
  return useNavigations().dashboard_side_menu;
});

function gotoDashboardMain() {
  navigateTo({
    name: "dashboard",
  });
}
</script>

<style scoped>
a.router-link-exact-active {
  font-weight: 500;
  /* background-color: #e7ebeb; */
  @apply bg-a-secondary-lightest;
  @apply border-l-4;
  @apply border-a-secondary;
  @apply text-a-secondary;
}
</style>
