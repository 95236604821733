<template>
  <div
      class="flex flex-col items-center min-h-screen h-screen justify-start text-a-neutral font-sans tracking-wide overflow-hidden"
  >
    <div class="flex flex-row w-full items-center justify-center h-20 border-b-2">
      <div class="flex flex-row items-center justify-between w-full max-w-4xl h-20">
        <NuxtImg
            :src="logoPath"
            width="120"
            class=""
            alt=""
        />
        <div
            class="flex flex-row items-center justify-center"
        >
          <div
              class="text-sm mx-2 hover:text-a-secondary cursor-pointer font-semibold transition duration-150"
              @click="gotoHome"
          >
            <span>Explore</span>
          </div>
        </div>
      </div>
    </div>
    <div
        class="flex flex-col items-center justify-center max-w-sm md:max-w-md lg:max-w-lg w-full min-h-full h-full"
    >
      <!-- Login Alert -->
      <div class="flex flex-row w-full mt-24">
      <BaseAlert
          animation="fade"
          :isRounded="true"
          :expandable="false"
      />
      </div>
      <!-- Login Alert Ends -->
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const logoPath =
    "/images/main/logo.png";


function gotoHome() {
  useRouter().replace({name: 'index'})
}


</script>

<style></style>
