<template>
  <div
      class="h-screen text-a-neutral font-sans tracking-wide overflow-hidden"
  >
    <DashboardNav class="hidden md:flex"/>
    <DashboardNavMobile class="flex md:hidden"/>
    <div
        class="flex flex-row w-full h-full"
    >
      <DashboardSideMenu class="hidden md:flex flex-none md:w-2/12 pt-8 border-r-2"/>
      <DashboardSideMenuMobile class="flex md:hidden"/>
      <div
          class="flex flex-col w-full overflow-y-auto pb-24 md:w-10/12 ml-2-12 pt-8 bg-a-background-light-primary"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style>
.ml-2-12 {
  margin-left: 16.6667%;
}

</style>
