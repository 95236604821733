import type {
    ApiError, ClassifiedAdSearchData,
    IslamicCenterReportType,
    IslamicCentersInstantSearchData, IslamicCentersSearchData,
    MainIslamicCenterData,
    MainIslamicCentersData
} from "~/types";

export const useMainIslamicCentersDataService = () => {
    const nuxtApp = useNuxtApp();
    const baseUrl = useRuntimeConfig().public.baseApiUrl;
    const route = useRoute();

    const fetchMainIslamicCenter = async () => {
        const {data, error} = await useAsyncData(`islamic-center-${route.params.slug}`,
            () => nuxtApp.$getIslamicCenterBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainIslamicCenterData;
        }
    }

    const fetchMainIslamicCentersData = async () => {
        const { data, error, refresh } = await useAsyncData(`main-islamic-centers`,
            () => useNuxtApp().$getIslamicCentersDataFromUrl(`${baseUrl}/islamic-centers`)
        );

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            console.log(data.value);
            return computed(()=>{
                return {
                    islamic_center_page:{
                        islamic_centers: data.value.data,
                        page_number: data.value.meta.current_page,
                    },
                    pagination_meta_data: data.value.meta,
                    pagination_links: data.value.links,
                } as MainIslamicCentersData
            });

        }
    };

    const fetchMainIslamicCentersDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getIslamicCentersDataFromUrl(url);
            return  {
                islamic_center_page:{
                    islamic_centers: response.data,
                    page_number: response.meta.current_page,
                },
                pagination_meta_data: response.meta,
                pagination_links: response.links,
            } as MainIslamicCentersData;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const fetchIslamicCenterReportTypes = async () => {
        const {data, error} = await useAsyncData('islamic-center-report-types',
            () => nuxtApp.$getIslamicCenterReportTypes()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as IslamicCenterReportType[];
        }
    }

    const requestToCreateNewIslamicCenterReport = async (data: object) => {
        try {
            const response = await nuxtApp.$createIslamicCenterReport(data);
            return response.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    //TODO:Request To Subscribe to Islamic Center Must Go Here
    

    const fetchInstantSearchResults = async (query: string) => {
        try {
            const response = await nuxtApp.$getInstantSearchResultsForIslamicCentersByQuery(query)
            return response as IslamicCentersInstantSearchData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchIslamicCentersBySearchCriteria = async(query:string)=>{
        try{
            const response = await nuxtApp.$getIslamicCentersBySearchCriteria(query);
            return response as IslamicCentersSearchData;
        }
        catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchMainIslamicCenter,
        fetchMainIslamicCentersData,
        fetchMainIslamicCentersDataFromUrl,
        fetchIslamicCenterReportTypes,
        requestToCreateNewIslamicCenterReport,

        fetchInstantSearchResults,
        fetchIslamicCentersBySearchCriteria
    }
}