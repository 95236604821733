declare module '#app' {
    interface NuxtApp {
        $authWithGoogle(): Promise<any>,
        $authWithFacebook(): Promise<any>,
        $getHomeData(): Promise<any>,
        $getClassifiedAdBySlug(slug:string): Promise<any>,
    }
}

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    const { makeFetchApiCallToUrl } = useMakeFetchApiCall()

    nuxtApp.provide('authWithGoogle', async () => {
        //return await makeFetchApiCallToUrl('get', `${config.public.baseWebUrl}/auth/google`)
        window.location.href = `${config.public.baseWebUrl}/auth/google`;
    })

    nuxtApp.provide('authWithFacebook', async () => {
        // return await makeFetchApiCallToUrl('get', `${config.public.baseWebUrl}/auth/facebook`)
        window.location.href = `${config.public.baseWebUrl}/auth/facebook`;
    })

    nuxtApp.provide('getHomeData', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/`)
    })
})