<template>
  <div
      class="flex flex-col items-center min-h-screen h-screen justify-start text-a-neutral font-sans tracking-wide overflow-hidden"
  >
    <div class="flex flex-row w-full items-center justify-center h-20 border-b-2">
      <div class="flex flex-row items-center justify-between w-full max-w-4xl h-20">
        <NuxtImg
            :src="logoPath"
            width="120"
            class=""
            alt=""
        />
        <div
            class="flex flex-row items-center justify-center"
        >
          <div
              class="text-sm mx-2 hover:text-a-secondary cursor-pointer font-semibold transition duration-150"
              @click="gotoHome"
          >
            <span>Explore</span>
          </div>
          <div
              v-if="useRoute().name!='login'"
              class="text-sm bg-a-secondary rounded-full text-white py-2 px-4 mx-2 hover:bg-a-secondary-dark cursor-pointer transition duration-150"
              @click="gotoLogin"
          >
            <span>Log In</span>
          </div>
          <div
              v-if="useRoute().name!='register'"
              class="text-sm bg-a-secondary rounded-full text-white py-2 px-4 mx-2 hover:bg-a-secondary-dark cursor-pointer  transition duration-150"
              @click="gotoRegister"
          >
            <span>Register For Free</span>
          </div>
        </div>
      </div>
    </div>
    <div
        class="flex flex-row w-full min-h-full h-full"
    >
      <div class="flex flex-col items-center justify-center min-h-full w-1/2">
        <LogoSwiper/>
      </div>
      <div class="flex flex-col items-center justify-center w-1/2 min-h-full h-full">
        <div class="flex flex-row w-full max-w-sm mx-8">
          <BaseAlert
              class="my-1"
              animation="fade"
              :isRounded="true"
          />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
const logoPath =
    "/images/main/logo.png";


function gotoHome() {
  useRouter().replace({name: 'index'})
}

function gotoLogin() {
  useRouter().replace({name: 'login'})
}

function gotoRegister() {
  useRouter().replace({name: 'register'})
}
</script>

<style></style>
