
export default defineNuxtRouteMiddleware((to, from) => {
    const authManager = useAuthManager();
    const isAccessingEmailVerificationPage = to.name == 'verify-email';
    const hasNoVerificationUrlLink = to.query.url == null || to.query.url == "";

    if (authManager.isLoggedIn.value) {
        if (authManager.authUser.value &&!authManager.authUser.value.is_email_verified) {
            if (isAccessingEmailVerificationPage && hasNoVerificationUrlLink) {
                console.log('navigating to verify-your-email route')
                return navigateTo({name: "verify-your-email"})
            }
            console.log('navigating to allow')
            return;
        } else {
            console.log('navigating to dashboard')
            return navigateTo({name: 'dashboard'});
        }
    } else {
        console.log('Redirecting to login with Query Param', to.fullPath)
        return navigateTo({
            path: '/login',
            query: {redirect: encodeURIComponent(to.fullPath as string)},
        })
    }
});