import { loadStripe, type Stripe, type StripeElements, type PaymentMethod, type CreatePaymentMethodData, type StripeCardElement, type StripeCardNumberElement, type StripeCardCvcElement, type StripeCardExpiryElement } from '@stripe/stripe-js';

declare module '#app' {
  interface NuxtApp {
    $stripe: StripeIntegration;
  }
}

interface StripeIntegration {
  stripe: Stripe | null;
  elements: StripeElements | null;
  cardElement: StripeCardElement | null;
  cardNumberElement: StripeCardNumberElement|null;
  cardCvcElement: StripeCardCvcElement|null;
  cardExpiryElement: StripeCardExpiryElement|null;
  attachCardElement: (elementId: string) => void;
  attachCardNumberElement: (elementId: string) => void;
  attachCardCvcElement: (elementId: string) => void;
  attachCardExpiryElement: (elementId: string) => void;
  destroyCardField:() => void,
  destroyCardNumberField:() => void,
  destroyCardCvcField:() => void,
  destroyCardExpiryField: ()=>void,
  getPaymentMethodForCardElement: (billingDetails: {cardholder_name:string, email:string, zip?:string, country?:string}) => Promise<PaymentMethod | undefined>;
  getPaymentMethodForCardNumber: (billingDetails: {cardholder_name:string, email:string, zip?:string, country?: string}) => Promise<PaymentMethod | undefined>;
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const stripePromise = loadStripe(useRuntimeConfig().public.stripeKey as string);
  const stripeStore = useStripe();

  const stripeIntegration: StripeIntegration = {
    stripe: null,
    elements: null,
    cardElement: null,
    cardNumberElement: null,
    cardCvcElement: null,
    cardExpiryElement: null,
    attachCardElement(elementId: string) {
      if (!this.elements) return;
      this.cardElement = this.elements.create('card');
      this.cardElement.mount(elementId);
      this.cardElement.on("focus", function() {
        stripeStore.setCardElementFocused();
      });
      // this.cardElement.on("blur", function(event:{error:any, complete:any, empty:any}) {
      //   stripeStore.setCardElementUnFocused();
      //   if (event.error) {
      //     stripeStore.setCardElementError(event.error.message);
      //   }
      //   if (event.complete) {
      //     stripeStore.unSetCardElementError();
      //   }
      //   if (event.empty) {
      //     stripeStore.setCardElementError("Details are required.");
      //   }
      // });
      this.cardElement.on("change", function(event) {
        if (event.error) {
          stripeStore.setCardElementError(event.error.message);
        }
        if (event.complete) {
          stripeStore.unSetCardElementError();
        }
        if (event.empty) {
          stripeStore.setCardElementError("Details are required.");
        }
      });
    },
    attachCardNumberElement(elementId: string) {
      if (!this.elements) return;
      this.cardNumberElement = this.elements.create('cardNumber');
      this.cardNumberElement.mount(elementId);
      this.cardNumberElement.on("focus", function() {
        stripeStore.setCardNumberElementFocused();
      });
  
      this.cardNumberElement.on("blur", function() {
        stripeStore.setCardNumberElementUnFocused();
      });
  
      this.cardNumberElement.on("change", function(event) {
        if (event.error) {
          stripeStore.setCardNumberElementError(event.error.message);
        }
        if (event.complete) {
          stripeStore.unSetCardNumberElementError();
        }
        if (event.empty) {
          stripeStore.setCardNumberElementError("Card Number is required.");
        }
      });
    },
    attachCardCvcElement(elementId: string) {
      if (!this.elements) return;
      this.cardCvcElement = this.elements.create('cardCvc');
      this.cardCvcElement.mount(elementId);
      this.cardCvcElement.on("focus", function () {
        stripeStore.setCardCvcElementFocused();
      });
      this.cardCvcElement.on("blur", function() {
        stripeStore.setCardCvcElementUnFocused();
      });
      this.cardCvcElement.on("change", function (event) {
        if (event.error) {
          stripeStore.setCardCvcElementError(event.error.message);
        }
        if (event.complete) {
          stripeStore.unSetCardCvcElementError();
        }
        if (event.empty) {
          stripeStore.setCardCvcElementError("Card Cvc is required.");          
        }
      });
    },
    attachCardExpiryElement(elementId: string) {
      if (!this.elements) return;
      this.cardExpiryElement = this.elements.create('cardExpiry');
      this.cardExpiryElement.mount(elementId);
      this.cardExpiryElement.on("focus", function () {
        stripeStore.setCardExpiryElementFocused();
      });
      this.cardExpiryElement.on("blur", function(event) {
        stripeStore.setCardExpiryElementUnFocused();
      });
      this.cardExpiryElement.on("change", function(event) {
        if (event.error) {
          useStripe().setCardExpiryElementError(event.error.message);
        }
        if (event.complete) {
          useStripe().unSetCardExpiryElementError();
        }
        if (event.empty) {
          useStripe().setCardExpiryElementError("Card Expiry is required.");
        }
      });
    },
    async getPaymentMethodForCardElement(billingDetails) {
      if (!this.stripe || !this.cardElement) return undefined;
      const paymentMethodData: CreatePaymentMethodData = {
        type: 'card',
        card: this.cardElement,
        billing_details: {
          name: billingDetails.cardholder_name,
          email: billingDetails.email,
          address: {
            postal_code: billingDetails.zip,
            country: billingDetails.country,
          }
        },
      };
      const { error, paymentMethod } = 
      await this.stripe.createPaymentMethod(paymentMethodData);
        if (error) {
          console.error(error.message);
          return undefined;
        }
      
      return paymentMethod;
    },
    async getPaymentMethodForCardNumber(billingDetails:{cardholder_name: string, email: string, zip?:string, country?:string}) {
      if (!this.stripe || !this.cardNumberElement) return undefined;
      const paymentMethodData: CreatePaymentMethodData = {
        type: 'card',
        card: this.cardNumberElement,
        billing_details: {
          name: billingDetails.cardholder_name,
          email: billingDetails.email,
          address: {
            postal_code: billingDetails.zip,
            country: billingDetails.country,
          }
        },
      };
      const { error, paymentMethod } = 
      await this.stripe.createPaymentMethod(paymentMethodData);
        if (error) {
          console.error(error.message);
          return undefined;
        }
      
      return paymentMethod;
    },
    destroyCardField() {
      if(this.cardElement != null){
        this.cardElement.clear();
        this.cardElement.destroy();
        this.cardElement = null;
      }
    },
   
    destroyCardNumberField() {
      if(this.cardNumberElement != null){
        this.cardNumberElement.clear();
        this.cardNumberElement.destroy();
        this.cardNumberElement = null;
        useStripe().unSetCardNumberElementError();
      }
    },
    
    destroyCardExpiryField() {
      if(this.cardExpiryElement != null){
      this.cardExpiryElement.clear();
      this.cardExpiryElement.destroy();    
      this.cardExpiryElement = null;
      useStripe().unSetCardExpiryElementError();
      }
    },
    
    destroyCardCvcField() {
      if(this.cardCvcElement != null){
        this.cardCvcElement.clear();
        this.cardCvcElement.destroy();
        this.cardCvcElement = null;
        useStripe().unSetCardExpiryElementError();
      }
    }
  
  };


  const stripe = await stripePromise;
  if (stripe) {
    stripeIntegration.stripe = stripe;
    stripeIntegration.elements = stripe.elements();
    //stripeIntegration.cardElement = stripeIntegration.elements.create('card');
  }

  nuxtApp.provide('stripe', stripeIntegration);
});