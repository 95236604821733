<template>
  <div class="flex flex-col w-full">
    <HomeHero/>
    <div
        class="flex w-full flex-col items-center justify-start"
    >
      <!-- {{ homepageResponse }} -->
      <div
          class="flex flex-col max-w-screen-2xl w-full px-2 md:px-4 lg:px-8 xl:px-16"
      >
        <HomeClassified
            :classifiedAds="
            homeClassifiedAds as HomeClassifiedAd[]
          "
            class="py-10"
        />
        <HomeBusinesses
            :businesses="homeBusinesses as HomeBusiness[]"
            class="py-10"
        />
        <HomeFundraising
            :fundraisingCampaigns="
            homeFundraisingCampaigns as HomeFundraisingCampaign[]
          "
            class="py-10"
        />
        <HomeNews
            :newsAnnouncements="
            homeNewsAnnouncements as HomeNewsAnnouncement[]
          "
            :newsPosts="homeLatestNewsPosts as NewsPost[]"
            class="py-10"
        />
        <HomeIslamicCenters
            :islamicCenters="
            homeIslamicCenters as HomeIslamicCenter[]
          "
            class="py-10"
        />
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script
    setup
    lang="ts"
>
import type {HomeBusiness, HomeClassifiedAd, HomeFundraisingCampaign, HomeNewsAnnouncement} from "~/types/main";
import type {HomeIslamicCenter, NewsPost} from "~/types";

useHead({
  title:
      "Businesses, Classified, Fundraising, Announcements, News",
});

definePageMeta({
  layout: "default",
});

const homeData = await useHomeDataManager().getHomeData();
console.log(homeData)
const homeBusinesses = computed(() => {
  return homeData?.home_businesses;
})
const homeClassifiedAds = computed(() => {
  return homeData?.home_classified_ads;
});
const homeFundraisingCampaigns = computed(() => {
  return homeData?.home_fundraising_campaigns;
});

const homeNewsAnnouncements = computed(() => {
  return homeData?.home_news_announcements;
});

const homeLatestNewsPosts = computed(() => {
  return homeData?.latest_news_posts;
});

const homeIslamicCenters = computed(() => {
  return homeData?.islamic_centers;
});
</script>

<style></style>
