<template>
  <div
      class="flex flex-row w-full bg-black bg-opacity-40 h-auto text-white"
  >
    <div
        class="flex flex-row items-center justify-evenly w-full max-w-screen-xl mx-auto h-full"
    >
      <!-- Brand Starts -->
      <nuxt-link
          :to="{ name: 'fundraising' }"
          class="flex flex-col w-2/12 pl-2 items-start justify-center cursor-pointer"
      >
        <NuxtImg
            src="/images/main/logo_inv.png"
            alt=""
            class="object-contain"
            height="40"
        />
        <p
            class="text-sm mx-auto font-semibold tracking-widest"
        >
          Fundraising
        </p>
      </nuxt-link>
      <!-- Brand Ends-->
      <!-- Buttons Section -->
      <div
          class="flex flex-row items-center justify-start w-5/12"
      >
        <ExploreDropDown/>
      </div>
      <!-- Buttons Section Ends -->
      <!-- Right Extra Section -->
      <div
          class="flex flex-row-reverse w-5/12 items-center justify-start"
      >
        <!-- User Dropdown -->
        <BaseUserDropdown
            v-if="useAuthManager().isLoggedIn.value"
        />
        <!-- User Dropdown Ends -->
        <div
            class="flex flex-row items-center justify-center"
            v-else
        >
          <nuxt-link
              :to="{ name: 'login' }"
              class="mx-1 px-1 hover:text-a-primary font-semibold text-sm"
          >
            Log In
          </nuxt-link>
          <BaseVerticalDivider color-class="border-a-neutral-dim" />
          <BaseButton
              primary-color-class="a-secondary"
              hovered-background-color-class="a-secondary-dark"
              text-size-class="text-sm"
              :is-slim="false"
              text-color-class="white"
              @click.native="useRouter().push({ name: 'register' })"
          >
            Get Started
          </BaseButton>
        </div>
        <BaseButton
            margin-classes="mx-2"
            primary-color-class="a-primary"
            hovered-text-color-class="a-neutral-darkest"
            text-size-class="text-sm"
            font-weight-class="font-medium"
            width-class="w-auto"
            text-color-class="hover:text-a-neutral-darkest"
            is-outlined
            @click.native="
            gotoCreateNewFundraisingCampaign
          "
        >
          <Icon
              name="mdi:plus"
          />
          New Fundraiser
        </BaseButton>
        <nuxt-link
            :to="{ name: 'help-support' }"
            class="mx-1 px-1 hover:text-a-primary font-medium text-sm"
        >
          Help
        </nuxt-link>
      </div>

      <!-- Right Extra Section Ends -->
    </div>
  </div>
</template>

<script setup lang="ts">
import {useAuthManager} from "~/composables/useAuthManager";

function gotoCreateNewFundraisingCampaign() {
  navigateTo({
    name: "dashboard-fundraising-create-new",
  });
}

const isLoggedIn = computed(()=>{
  return useAuthManager().isLoggedIn.value;
});
</script>

<style></style>
